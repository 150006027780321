html {
  background-color: white
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.imgInCarousel{
  width: 100%;
  height: 100vh;
  max-height: 100vh;
  object-fit: contain;
}

.thumbnail-wrapper {
  position: relative;
  height: 500px;
  overflow: hidden;
  margin: 0 auto;
}

.thumbnail-wrapper img {
  position: absolute;
  left: 50%;
  top: 50%;
   -moz-transform: translate(-50%, -50%);
   -webkit-transform: translate(-50%, -50%);
   transform: translate(-50%, -50%);
   width: 100%;
}

.carousel .thumbs-wrapper{
  display: none;
}

.carousel .carousel-status{
  display: none;
}

.on-hover:hover{
  cursor: pointer;
}

.table-p-no-space p{
  margin: 0;
  padding: 0;
}

.infoTextInAddPayment{
  margin-top: 15px;
  background-color: #F0F8FF;
  text-align: center;
  padding: 2px 2px;
  font-size: 13px;
  font-weight: 600;
  border-radius: 10px;
}

.menu-more ul li{
  min-height: 5px;
  font-size: 0.9rem;
}

.history-card{
  border: 1px solid lightgray;
  padding: 5px;
  border-radius: 5px;
  margin-bottom: 5px;
}
.history-card p{
  margin: 0;
  font-size: 14px;
}

.history-card span {
  font-weight: 600;
}

.rbc-event{
  background-color: #5191FA;
  font-size: 13px;
}